import './slider.scss'

import Hero from '../slider.json';
import Img1 from '../assets/hero2.jpg';
import Img2 from '../assets/fliserensning.jpg';

import { useState, useEffect } from 'react';


const Slider = ({ duration = "5000" }) => {

    const [imgArr] = useState([Img1, Img2])
    const [activeIndex, setActiveIndex] = useState(0)
    const [txtArr] = useState(["Algebehandling af tag fra 599,- ex. moms", "Effektiv rensning af fliser fra 39,- pr. m2 ex. moms"])
    

    useEffect(() => {
        var timeout = setTimeout(() => {


            if (activeIndex < imgArr.length - 1) {
                setActiveIndex(activeIndex + 1);
            } else {
                setActiveIndex(0)
            }

        }, duration);
        return () => {
            clearTimeout(timeout)
        }
    }, [activeIndex]);


    return (
        <section className='slider'>
            <ul>
                {
                    imgArr?.map((item, index) => (
                        <li className={index === activeIndex ? "active" : ""} style={{ backgroundImage: "url(" + item + ")", animationDuration: duration + "ms" }}>
                            <img hidden alt="hero" src={item} />
                           
                        </li>
                    ))

                }
                {
                    txtArr?.map((item, index) => (
                        <h2 className={index === activeIndex ? "active" : ""}>{item}</h2>
                    ))
                }
            </ul>
        </section>
    )
}

export default Slider;