import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footerinfo'>
        <h3>S 98 ApS</h3>
        <p>Stavneagervej 24</p>
        <p>8250 Egå</p>
        <p>Tlf. 70 227 210</p>
        <p>E-mail: s98@s98.dk</p>
        <p>CVR nr. 44670887</p>
        </div>
    </div>
  )
}

export default Footer