import './App.scss';
import Header from './components/Header';
import Behandling from './components/Behandling';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      
      <Header />
      <Behandling />
      <Footer />
    </div>
  );
}

export default App;
