import React from 'react'
import '../App.scss'
import Slider from '../components/Slider';


const Header = () => {
  return (
    <div className='header'>
        <div className="navbar">
            <h1>S98 ApS</h1>
        </div>
        <div className="imageslider">

          <Slider />
           {/* <h2>Algebehandling af tag fra 599,-<br/><span>ex. moms</span></h2>
            <img src={Hero} alt="tagrensning"></img>    */}
        </div>
    </div>
  )
}

export default Header