import React from 'react';
import '../App.scss';
import Flise from '../assets/fliserens1.jpg'

const Behandling = () => {
  return (
    <div className='behandling'>
        <div className='behandlingtxt'>
            <h1>Behandling af ukrudt, mos, alger.</h1>
            <p className='p1'>Vores behandlings program omfatter minimum 3 gange årligt, evt. flere gange afhængig af behovet:</p>

<p className='p2'>
<span>1.</span> Forårsbehandling - Jordmidler der forhindre græsarter-frø i at spire. <br/>
<span>2.</span> Efterkontrol af førstegangsbehandling. <br/>
<span>3.</span> Efterårsbehandling -  der er en vigtig behandling.<br/>
</p>
<p className='p3'>Vi renser effektivt alle grønne områder, arealer (belagt) med fliser, sten, grus o.l., samt oplagspladser m.v. for al slags ukrudt - også den genstridige.<br/><br/>

Naturligvis anvender vi kun godkendte, miljøvenlige behandlingsmidler afstemt efter årstid, ukrudtets art og grunden beskaffenhed.<br/><br/>

Vi yder <span>fuld garanti</span> for hver udført behandling. Vi har mange års erfaring og er certificeret.</p>
        </div>
        <div className='behandlingpic'>
            <img src={Flise} alt="fliserens"></img>
        </div>
        <div className='kontakt'>
            <h1>Kontakt os her</h1>
            <p>Telefon: 70 227 210</p>
            <p>E-mail: s98@s98.dk</p>
        </div>
    </div>
  )
}

export default Behandling